// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---thalhalla-gatsby-theme-thalhalla-src-pages-docs-js": () => import("./../../../../@thalhalla/gatsby-theme-thalhalla/src/pages/docs.js" /* webpackChunkName: "component---thalhalla-gatsby-theme-thalhalla-src-pages-docs-js" */),
  "component---thalhalla-gatsby-theme-thalhalla-src-pages-examples-js": () => import("./../../../../@thalhalla/gatsby-theme-thalhalla/src/pages/examples.js" /* webpackChunkName: "component---thalhalla-gatsby-theme-thalhalla-src-pages-examples-js" */),
  "component---thalhalla-gatsby-theme-thalhalla-src-pages-github-js": () => import("./../../../../@thalhalla/gatsby-theme-thalhalla/src/pages/github.js" /* webpackChunkName: "component---thalhalla-gatsby-theme-thalhalla-src-pages-github-js" */),
  "component---thalhalla-gatsby-theme-thalhalla-src-pages-index-js": () => import("./../../../../@thalhalla/gatsby-theme-thalhalla/src/pages/index.js" /* webpackChunkName: "component---thalhalla-gatsby-theme-thalhalla-src-pages-index-js" */)
}

